<template>
  <div style="background-color: #FAFAFA;padding-top: 25px">
    <div style="background-color: white">
      <div v-show="true" class="center">
        <div style="height: 120px;border-bottom: 1px dashed rgba(220, 220, 220, .5);display: flex;align-items: center">
          <Select v-model="cgmc" style="width: 150px;margin-right: 10px">
            <Option value="ans">申请号</Option>
            <Option value="pa">高校名称</Option>
            <Option value="pin">发明人</Option>
            <Option value="sic">专利分类号</Option>
            <Option value="agc">代理机构</Option>
            <Option value="agt">代理人</Option>
            <Option value="keywords">技术关键词</Option>
          </Select>
          <Input style="width: 350px;margin-right: 30px" v-model="input" />
          <div
            style="color:white;border-radius:2px;height: 40px;cursor: pointer;width: 100px;line-height: 40px;text-align: center;background-color: #36c2cf"
            @click="search()">检索
          </div>
        </div>
        <div style="padding: 30px 0">
          <div style="height: auto;border: 1px solid rgba(220, 220, 220, .5)">
            <div class="cgfl">
              <span>专利类型</span>&nbsp;&nbsp;&nbsp;
              <CheckboxGroup v-model="zllx" @on-change="delAll()">
                <Checkbox label="全部"></Checkbox>
                <Checkbox label="发明专利"></Checkbox>
                <Checkbox label="实用新型"></Checkbox>
                <Checkbox label="外观专利"></Checkbox>
              </CheckboxGroup>
            </div>
            <div></div>
            <div class="cgfl" style="border-bottom: none">
              <span>法律状态</span>&nbsp;&nbsp;&nbsp;
              <CheckboxGroup v-model="flzt"  @on-change="delAll2()">
                <Checkbox label="全部"></Checkbox>
                <Checkbox label="在审"></Checkbox>
                <Checkbox label="有效"></Checkbox>
                <Checkbox label="无效"></Checkbox>
                <Checkbox label="有效期届满"></Checkbox>

              </CheckboxGroup>
            </div>
          </div>
        </div>
        <div>
          <Table :columns="columns1" :data="data1" @on-row-click="seeDetail"       :disabled-hover="true"></Table>
        </div>
        <div style="padding-top: 40px;overflow: hidden;padding-bottom: 200px">
          <Page :total="total" show-elevator style="margin-top: 20px;float: right" @on-change="pageChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPatent } from "@/plugins/api/resourceApi";
import { warnToast,errorToast } from "@/plugins/tools/util";

export default {
  name: "ResourceRetrieval",
  mounted() {
    let keyword = this.$route.query.keyword
    this.params.keywords = keyword;
    this.cgmc = "keywords";
    this.input = keyword
    this.getPatentList();
  },
  data() {
    return {
      total: 10,
      params: {
        pageNum: 1,
        pageSize: 10,
        keywords: "",
        ans: "",
        pa: "",
        pin: "",
        agc: "",
        agt: "",
        sic: "",
        sectionName: "",
        lastLegalStatus: ""
      },
      cgmc: "",
      input: "",
      zllx: ['全部'],
      flzt: ['全部'],
      columns1: [
        {
          title: "专利图片",
          key: "imageUrl",
          align: "center",
          render: (h, params) => {
            return h("img", {
              style: {//设置样式
                width: '50%',
                height: '50%',
                display:'inline-block'
              },
              attrs: {//设置属性
                src: "https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/znsearch/images/i2.png"
              }
            });
          }
        },
        {
          title: "申请号",
          key: "an",
          align: "center"
        },

        {
          title: "专利名称",
          key: "ti",
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  display: '-webkit-box',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical'
                },
                domProps: {
                  title: params.row.ti
                }
              }, params.row.ti)
            ])
          }
        },
        {
          title: "专利类型",
          key: "sectionName",
          align: "center"
        }
        ,
        {
          title: "专利权利人",
          key: "pa",
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  display: '-webkit-box',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical'
                },
                domProps: {
                  title: params.row.pa
                }
              }, params.row.pa)
            ])
          }

        }
        ,
        {
          title: "专利发明人",
          key: "pin",
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  display: '-webkit-box',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical'
                },
                domProps: {
                  title: params.row.pin
                }
              }, params.row.pin)
            ])
          }
        }
        ,
        {
          title: "法律状态",
          key: "lastLegalStatus",
          align: "center"
        }
      ],
      data1: []
    };
  },
  methods: {
    async getPatentList() {
      let flag = false;
      for(let item in this.params){
        if(item !== 'pageNum' && item !== 'pageSize'){
          if(this.params[item] != ""){
            flag = true
          }
        }
      }
      if(flag == false){
        warnToast("请至少选择一种进行查询")
      }else{
        let json = await getPatent(this.params);
        if (json.code == 0) {
          this.data1 = json.result.list;
          this.data1.forEach((item,index)=> {
            if(item.sectionName == 'FMZL'){
              item.sectionName = '发明专利'
            }
            if(item.sectionName == 'FMSQ'){
              item.sectionName = '发明专利'
            }
            if(item.sectionName == 'SYXX'){
              item.sectionName = '实用新型'
            }
            if(item.sectionName == 'WGSJ'){
              item.sectionName = '外观设计'
            }
            if(item.sectionName == 'WGZL'){
              item.sectionName = '外观设计'
            }
          })
          this.total = json.result.total;
          // keywords: "",
          //     ans: "",
          //     pa: "",
          //     pin: "",
          //     agc: "",
          //     agt: "",
          //     sic: "",
          //     sectionName: "",
          //     lastLegalStatus: ""
          // this.params.keywords = ""
          // this.params.ans = ""
          // this.params.pa = ""
          // this.params.pin = ""
          // this.params.agc = ""
          // this.params.agt = ""
          // this.params.sic = ""
          // this.params.sectionName = ""
          // this.params.lastLegalStatus = ""

        } else {
          errorToast(json.message);
        }
      }

    },
    delAll(){
      if(this.zllx.length >= 2 && this.zllx.indexOf("全部") > -1){
        this.zllx.forEach((item,index) => {
          if(item == '全部'){
            this.$nextTick(()=>{

              this.zllx = ['全部']
            })
          }
        })
      }
    },
    delAll2(){
      if(this.flzt.length >= 2 && this.flzt.indexOf("全部") > -1){
        this.flzt.forEach((item,index) => {
          if(item == '全部'){
            this.$nextTick(()=>{

              this.flzt = ['全部']
            })
          }
        })
      }
    },
    search() {
      this.params.pageNum = 1;
      this.params[this.cgmc] = this.input;
      this.sectionName = "";
      this.lastLegalStatus = "";
      for(let item in this.params){
        if(this.params[item] != "" && item != this.cgmc){
          this.params[item] = "";
        }
      }
      // 法律状态
      for (let i = 0; i < this.flzt.length; i++) {
        let info = this.flzt[i];
        if (info === "全部") {
          this.lastLegalStatus = "";
        } else if (info === "在审") {
          this.lastLegalStatus += "1,";
        } else if (info === "有效") {
          this.lastLegalStatus += "2,";
        } else if (info === "无效") {
          this.lastLegalStatus += "3,";
        } else if (info === "有效期届满") {
          this.lastLegalStatus += "4,";
        }
      }
      if(this.lastLegalStatus != ""){
        this.lastLegalStatus =
            this.lastLegalStatus.substring(0, this.lastLegalStatus.length-1);
      }
      // 专利类型
      for (let i = 0; i < this.zllx.length; i++) {
        let info = this.zllx[i];
        if (info === "全部") {
          this.sectionName = "";
        } else if (info === "发明专利") {
          this.sectionName += "1,";
        } else if (info === "实用新型") {
          this.sectionName += "2,";
        } else if (info === "外观专利") {
          this.sectionName += "3,";
        }
      }
      if(this.sectionName != ""){
        this.sectionName =
            this.sectionName.substring(0, this.sectionName.length-1);
      }
      this.params.sectionName = this.sectionName;
      this.params.lastLegalStatus = this.lastLegalStatus;

      this.getPatentList();
    },
    changePage(index) {
      this.params.pageNum = index;
      this.getPatentList();
    },
    pageChange(index) {
      this.params.pageNum = index;
      this.getPatentList();
    },
    seeDetail(row, index) {
      let routeUrl = this.$router.resolve({
        path: "/home/ResourceDetail",//新页面地址
        query: { an: row.an }//携带的参数
      });
      window.open(routeUrl.href, "_blank");
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ .ivu-table td {
  height: 80px !important;
}

/deep/ .ivu-table th {
  height: 60px !important;
}

.more {
  display: inline-block;
  float: right;
  width: auto !important;
  cursor: pointer;
}

/deep/ .ivu-page-item-active {
  background-color: #36c2cf;
  color: white !important;
  border: none;
}

/deep/ .ivu-page-item-active a {
  color: white !important;
}

/deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
  border: #36c2cf;
  background-color: #36c2cf;
}

/deep/ .ivu-checkbox-group {
  display: inline-block;
}
/deep/ .ivu-table-tbody tr:nth-child(2n) td {
  background-color: rgba(55, 197, 207, 0.059) !important;
}

/deep/ .ivu-table-row:hover {
  box-shadow: 15px 15px 15px 0px rgba(127, 127, 127, 0.1)!important;
}
/deep/ .ivu-table-tbody tr:nth-child(2n):hover {
  box-shadow: 20px 40px 15px 20px rgba(54, 194, 207,.1) inset!important;
}
.cgfl {
  position: relative;
  height: 50px;
  border-bottom: 1px solid rgba(220, 220, 220, .5);
  padding: 15px 30px;

  & span {
    display: inline-block;
    width: 100px;
  }

}

/deep/ .ivu-select-selection {
  border-radius: 0;
}

.center {
  width: 1200px;
  margin: 0 auto;
}
</style>
