import {get} from "../http/http";
import {resourceUrl} from "../http/baseUrl";

// 专利检索
export const getPatent = (params) => {
  return get(`${resourceUrl}/v1/patent/search`,params)
};

// 专利详情

export const getPatentDetail = (params) => {
  return get(`${resourceUrl}/v1/patent/detail`,params)
};


//成功检索
export const getResult = (params) => {
  return get(`${resourceUrl}/v1/patent/achievement/search`,params)
};

export const getResultDetail = (params) => {
  return get(`${resourceUrl}/v1/patent/achievement/detail`,params)
};


